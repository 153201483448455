<template>
	<div class="mb-5 pb-5">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<router-link :to="{name: 'games-index'}">Games</router-link>
				</li>
				<li class="breadcrumb-item active" v-if="model">
					<router-link :to="{name: 'games-game_id', params: {game_id: $route.params.game_id}}">{{
							model.Name
						}}
					</router-link>
				</li>
			</ol>
		</nav>

		<game-form v-model="model"></game-form>
		<button class="btn btn-primary mb-5" @click="save">SAVE</button>
	</div>
</template>

<script>
import api from '@/api'
import GameForm from "../../components/games/game-form";

export default {
	name: "game_id",
	components: {GameForm},
	data: () => ({
		model: null,
	}),
	async mounted() {
		this.model = await api.Games.get(this.$route.params.game_id)
	},
	methods: {
		async save() {
			let game = await api.Games.update(this.$route.params.game_id, this.model)

			alert("Saved")

		}
	}
}
</script>

<style scoped>

</style>
